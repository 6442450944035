.auth-container {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 50px;
  }
  
  .auth-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .auth-form button {
    padding: 10px 20px;
    background-color: #34a6f2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .auth-form p {
    color: red;
    margin-top: 10px;
  }
  