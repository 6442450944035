.message-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .message-list {
    display: flex;
    flex-direction: column;
  }
  
  .message-bubble {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
  }
  